<!--景点地图覆盖物-->
<template>
    <div class="Spot-Overlay-Point">
        <div class="outerFrame">
            <div class="innerBox">
                <img @click="openMap" :src="resourceInfo.imageUrl ? resourceInfo.imageUrl : noPic"/>
            </div>
            <div class="Pointer"></div>
        </div>
        <span class="Name">{{ resourceInfo.nameCn }}</span>
        <map-navigation ref="mapNavigation"></map-navigation>
    </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';
import {WeChatGlMixins} from "@/utils/wechatGl";
import MapNavigation from "@/components/MapNavigation.vue";
export default {
    name: "SpotOverlayPointUtil",
    mixins: [WeChatGlMixins],
    data() {
        // 这里存放数据
        return {
            noPic: require("@/assets/png/noPic.png"),
            resourceInfo: ''
        }
    },
    // import 引入的组件需要注入到对象中才能使用
    components: {MapNavigation},
    props: {},
    // 方法集合
    methods: {
        openMap() {
            this.$refs.mapNavigation.open([{la: this.resourceInfo.latitude,ln: this.resourceInfo.longitude,pointName: this.resourceInfo.nameCn}])
        }
    },
    // 计算属性 类似于 data 概念
    computed: {},
    // 监控 data 中的数据变化
    watch: {},
    //过滤器
    filters: {},
    // 生命周期 - 创建之前
    beforeCreate() {
    },
    // 生命周期 - 创建完成（可以访问当前this 实例）
    created() {
    },
    // 生命周期 - 挂载之前
    beforeMount() {
    },
    // 生命周期 - 挂载完成（可以访问 DOM 元素）
    mounted() {
    },
    // 生命周期 - 更新之前
    beforeUpdate() {
    },
    // 生命周期 - 更新之后
    updated() {
    },
    // 生命周期 - 销毁之前
    beforeDestroy() {
    },
    // 生命周期 - 销毁完成
    destroyed() {
    },
    // 如果页面有 keep-alive 缓存功能,这个函数会触发
    //进入的时候触发
    activated() {
    },
    //离开的时候触发
    deactivated() {
    },
}
</script>

<style scoped lang="scss">
.Spot-Overlay-Point {
  position: absolute;
  z-index: 9999;

  .Name {
    font-size: 12px;
    display: flex;
    color: chocolate;
    font-weight: 700;
    position: absolute;
    top: 55px;
    white-space: nowrap;
    transform: scale(0.8);
  }

  .outerFrame {
    background-color: #0a0;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;

    .Pointer {
      height: 20px;
      width: 20px;
      background-color: #0a0;
      position: absolute;
      bottom: -2px;
      transform: rotate(45deg);
    }

    .innerBox {
      background-color: white;
      border-radius: 50%;
      height: 45px;
      width: 45px;
      position: absolute;
      z-index: 100;

      > img {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
}
</style>
