var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Spot-Overlay-Point" },
    [
      _c("div", { staticClass: "outerFrame" }, [
        _c("div", { staticClass: "innerBox" }, [
          _c("img", {
            attrs: {
              src: _vm.resourceInfo.imageUrl
                ? _vm.resourceInfo.imageUrl
                : _vm.noPic,
            },
            on: { click: _vm.openMap },
          }),
        ]),
        _c("div", { staticClass: "Pointer" }),
      ]),
      _c("span", { staticClass: "Name" }, [
        _vm._v(_vm._s(_vm.resourceInfo.nameCn)),
      ]),
      _c("map-navigation", { ref: "mapNavigation" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }