export const MapMinxins = {
    methods: {
        getCityLatitudeAndLongitude(cityList) {
            let arr = []
            cityList.forEach(item => {
                if (item.latitude && item.longitude) {
                    arr.push(`${item.latitude},${item.longitude}`)
                }
            })
            return arr
        },
        // 通过BudgetList获取内部的经纬度
        getBugetListLatitudeAndLongitude(budgetList, state) {
            console.log("getBugetListLatitudeAndLongitude",budgetList)
            let arr = []
            // 取该天城市的经纬度
            if (state === -1) {
                budgetList.forEach(item => {
                    var resourceDetail = item.resourceDetail;
                    if (resourceDetail.latitude && resourceDetail.longitude) {
                        arr.push(`${resourceDetail.latitude},${resourceDetail.longitude}`)
                    }
                })
            }
            // 取该方案所有城市的经纬度
            else if (state === -2) {

            } else {
                let v = budgetList.filter(x => x.state === state)
                v.forEach(item => {
                    var resourceDetail = item.resourceDetail;
                    if (resourceDetail.latitude && resourceDetail.longitude) {
                        arr.push(`${resourceDetail.latitude},${resourceDetail.longitude}`)
                    }
                })
            }
            return arr
        },
        // 转成百度坐标
        changeBaiduPoint(value) {
            let points = []
            value.forEach(item => {
                let add = item.split(",")
                points.push(new BMapGL.Point(add[1], add[0]))
            })
            return points;
        },
        changePoint(value) {
            console.log("dfksdkfdf", value)
            let points = []
            value.forEach(item => {
                let add = item.split(",")
                points.push({'lng': add[1], 'lat': add[0]})
            })
            return points;
        },
        // 转成彩虹渐变线坐标
        convertToRainbowGradientLineCoordinates(value) {
            let v = []
            value.forEach(item => {
                let arr = item.split(",")
                v.push([Number(arr[1]), Number(arr[0])])
            })
            console.log("转成彩虹渐变线坐标", v, value)
            return v
        },
        getPointsCenter(points) {
            var point_num = points.length; //坐标点个数
            var X = 0, Y = 0, Z = 0;
            for (let i = 0; i < points.length; i++) {
                if (points[i] == '') {
                    continue;
                }
                let point = points[i].split(',');
                var lat, lng, x, y, z;
                lat = parseFloat(point[0]) * Math.PI / 180;
                lng = parseFloat(point[1]) * Math.PI / 180;
                x = Math.cos(lat) * Math.cos(lng);
                y = Math.cos(lat) * Math.sin(lng);
                z = Math.sin(lat);
                X += x;
                Y += y;
                Z += z;
            }
            X = X / point_num;
            Y = Y / point_num;
            Z = Z / point_num;

            var tmp_lng = Math.atan2(Y, X);
            var tmp_lat = Math.atan2(Z, Math.sqrt(X * X + Y * Y));
            console.log("skdkfskfsdfksdjfk", [tmp_lat * 180 / Math.PI, tmp_lng * 180 / Math.PI])
            return [tmp_lat * 180 / Math.PI, tmp_lng * 180 / Math.PI];
        },
        //  2.计算缩放级别的函数
        getZoom(maxLng, minLng, maxLat, minLat, map) {
            var zoom = [
                "50", "100", "200", "500", "1000", "2000", "5000", "10000",
                "20000", "25000", "50000", "100000", "200000", "500000",
                "1000000", "2000000", "4000000", "8000000", "16000000"
            ]//级别18到3。
            //最大最小的坐标点
            var pointA = new BMapGL.Point(maxLng, maxLat);  // 创建点坐标A
            var pointB = new BMapGL.Point(minLng, minLat);  // 创建点坐标B
            var distance = map.getDistance(pointA, pointB).toFixed(1);  //获取两点距离,保留小数点后两位
            console.log('getZoom', pointA, pointB, distance)
            for (var i = 0, zoomLen = zoom.length; i < zoomLen; i++) {
                if (zoom[i] - distance > 0) {
                    return 18 - i + 2.7;
                    //地图范围常常是比例尺距离的10倍以上 所以加3
                }
            }
            ;
        },
        //1.根据原始数据计算中心坐标和缩放级别，并为地图设置中心坐标和缩放级别。
        setPointZoom(points, map) {
            console.log("shuju", points)
            if (points.length > 0) {
                var maxLng = points[0].lng;
                var minLng = points[0].lng;
                var maxLat = points[0].lat;
                var minLat = points[0].lat;
                var res;
                for (var i = points.length - 1; i >= 0; i--) {
                    res = points[i];
                    if (res.lng > maxLng) maxLng = res.lng;
                    if (res.lng < minLng) minLng = res.lng;
                    if (res.lat > maxLat) maxLat = res.lat;
                    if (res.lat < minLat) minLat = res.lat;
                }
                ;
                var cenLng = (parseFloat(maxLng) + parseFloat(minLng)) / 2;
                var cenLat = (parseFloat(maxLat) + parseFloat(minLat)) / 2;
                var zoom = this.getZoom(maxLng, minLng, maxLat, minLat, map);
                console.log("中心坐标+缩放等级", cenLng, cenLat, zoom)
                map.centerAndZoom(new BMapGL.Point(cenLng, cenLat), zoom);
            } else {
                //没有坐标，显示全中国
                //map.centerAndZoom(new BMapGL.Point(103.388611,35.563611), 5);
            }
        },
    }
}

