<template>
    <van-action-sheet
        v-model="isShowSheet"
        title="使用地图打开"
        :actions="sheetList"
        cancel-text="取消"
        description="如果点击无响应，可能是您还没有安装该APP"
        close-on-click-action
        @select="handleSheetSelect"
    />
</template>

<script>
export default {
    name: "MapNavigation",
    data() {
        return {
            isShowSheet: false,
            sheetList: [
                {
                    name: '百度地图',
                    id: 1,
                },
                {
                    name: '高德地图',
                    id: 2,
                },
            ],
            //定义起点位置
            StartXY: {
                lng: 116.327404,
                lat: 39.971385,
                city: '北京市'
            },
            //定义终点位置
            EndXY: {
                lng: 116.545123,
                lat: 39.915599,
                city: "北京市",
            },
            // 出发地
            placeOfDeparture: '',
            // 目的地
            destination: '',
            pointMap: []
        }
    },
    methods: {
        open(pointMap) {
            this.isShowSheet = true
            this.pointMap = pointMap
            console.log("opne",this.pointMap)
        },
        handleSheetSelect(action) {
            if (this.pointMap.length > 0) {
                this.navToMap(action.id)
            }
        },
        /**
         * 打开高德或百度地图
         * @param {*} latitude
         * @param {*} longitude
         * @param {*} name  导航目的地名称
         * @param {*} type 1 百度地图 2 高德地图
         */
        navToMap(type = 1) {
            let url
            const u = navigator.userAgent
            console.log("userAgent",navigator.userAgent)
            //判断ios
            const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
            //判断Android
            const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1
            if (type == 1) {
                //百度地图 把获取到的非百度坐标转为百度坐标
            } else if (type == 2) {
                //高德地图 把获取到的非GCJ-02坐标转为GCJ-02(火星坐标)
            }
            let baiduLocationArr = []
            let baiduLocationNameArr = []
            this.pointMap.forEach((item) => {
                baiduLocationArr.push(item.la);
                baiduLocationArr.push(item.ln);
            })

            if (isAndroid) {
                switch (type) {
                    case 1: //百度地图
                        if (this.pointMap.length === 1) {
                            url = `http://api.map.baidu.com/marker?location=${this.pointMap[0].la},${this.pointMap[0].ln}&title=${this.pointMap[this.pointMap.length-1].pointName}&content=${this.pointMap[this.pointMap.length-1].pointName}&output=html`
                        } else {
                            url = `http://api.map.baidu.com/direction?origin=${this.pointMap[0].pointName}&destination=${this.pointMap[this.pointMap.length - 1].pointName}&mode=driving&region=${this.pointMap[0].pointName}&output=html&src=webapp.baidu.openAPIdemo`;
                        }
                        // url = `http://api.map.baidu.com/marker?location=${baiduLocationArr.join(",")}&title=${this.pointMap[0].pointName}&content=${this.pointMap[this.pointMap.length-1].pointName}&output=html`
                        break
                    case 2: //高德地图
                        if (this.pointMap.length === 1) {
                            url = `https://uri.amap.com/navigation?to=${this.pointMap[this.pointMap.length-1].ln},${this.pointMap[this.pointMap.length-1].la},${this.pointMap[this.pointMap.length-1].pointName}&callnative=1`
                        } else {
                            url = `https://uri.amap.com/navigation?from=${this.pointMap[0].ln},${this.pointMap[0].la},${this.pointMap[0].pointName}&to=${this.pointMap[this.pointMap.length - 1].ln},${this.pointMap[this.pointMap.length - 1].la},${this.pointMap[this.pointMap.length - 1].pointName}&callnative=1`
                        }
                        break
                    default:
                        break
                }
            } else if (isIOS) {
                switch (type) {
                    case 1: //百度地图
                        if (this.pointMap.length === 1) {
                            url = `http://api.map.baidu.com/marker?location=${this.pointMap[0].la},${this.pointMap[0].ln}&title=${this.pointMap[this.pointMap.length-1].pointName}&content=${this.pointMap[this.pointMap.length-1].pointName}&output=html`
                        } else {
                            url = `http://api.map.baidu.com/direction?origin=${this.pointMap[0].pointName}&destination=${this.pointMap[this.pointMap.length - 1].pointName}&mode=driving&region=${this.pointMap[0].pointName}&output=html&src=webapp.baidu.openAPIdemo`;
                        }
                        // url = `http://api.map.baidu.com/marker?location=${baiduLocationArr.join(",")}&title=${this.pointMap[0].pointName}&content=${this.pointMap[this.pointMap.length-1].pointName}&output=html`
                        break
                    case 2: //高德地图
                        if (this.pointMap.length === 1) {
                            url = `https://uri.amap.com/navigation?to=${this.pointMap[this.pointMap.length-1].ln},${this.pointMap[this.pointMap.length-1].la},${this.pointMap[this.pointMap.length-1].pointName}&callnative=00`
                        } else {
                            url = `https://uri.amap.com/navigation?from=${this.pointMap[0].ln},${this.pointMap[0].la},${this.pointMap[0].pointName}&to=${this.pointMap[this.pointMap.length - 1].ln},${this.pointMap[this.pointMap.length - 1].la},${this.pointMap[this.pointMap.length - 1].pointName}&callnative=00`
                        }
                        break
                    default:
                        break
                }
            }
            // console.log("jksjdksd",`http://api.map.baidu.com/marker?location=${baiduLocationArr.join(",")}&title=${baiduLocationNameArr.join(",")}&content=${baiduLocationNameArr.join(",")}&output=html`)
            window.location.href = url
        },
    }
}
</script>

<style scoped>

</style>
