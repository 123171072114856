<template>
    <div style="width: auto;margin-top: 20px">
        <div class="cityNode">
            <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
                地图概览
            </van-notice-bar>
            <div id="CityDetailMap" class="CPM11"></div>
        </div>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';
import {mapMutations, mapState} from "vuex";
import {MapMinxins} from "@/components/Common/Js/MapMinxins";
import SpotOverlayPointUtil from "@/components/Common/Js/SpotOverlayPointUtil";
import {formatDate} from "@/utils/DateUtil";
import CityOverlayPointUtil from "@/components/Common/Js/CityOverlayPointUtil";

export default {
    name: "PlanDetailMap",
    data() {
        // 这里存放数据
        return {
            planMap: ''
        }
    },
    mixins: [MapMinxins],
    // import 引入的组件需要注入到对象中才能使用
    components: {},
    props: {
        'planDayMap': {
            type: Object,
            default: () => {
                return {}
            }
        },
        'state': {
            type: Number,
            default: () => {
                return -1
            }
        },
        'planDayList': {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    // 方法集合
    methods: {
        ...mapMutations('collection', ['SET_DAY_DATA']),
        // 彩虹渐变线
        lineLayer() {
            console.log("lineLayer", this.planDayMap)
            let value = []
            if (this.state === -1) {
                value = this.getCityLatitudeAndLongitude(this.planDayMap.cityList)
            } else if (this.state === -2) {
                let cityList = []
                this.planDayList.forEach(item => {
                    cityList.push(...item.cityList)
                })
                value = this.getCityLatitudeAndLongitude(cityList)
            }
            else {
                value = this.getBugetListLatitudeAndLongitude(this.planDayMap.budgetList, this.state)
            }
            this.setPointZoom(this.changePoint(value), this.planMap)
            var convertToRainbowGradientLineCoordinates = this.convertToRainbowGradientLineCoordinates(value);
            var view = new mapvgl.View({
                map: this.planMap
            });
            // 倾斜度
            // this.planMap.setTilt(30);
            var lineLayer = new mapvgl.LineRainbowLayer({
                style: 'road', // road, arrow, normal
                width: 3,
                // color: ['#a09']
                // color: ['#2DC4BB']
                color: ['#f00', '#f0f', '#0af', '#0a0', '#F09', '#a09', '#ff0', '#fd0', '#f90', '#f00']
            });
            view.addLayer(lineLayer);
            var data = [{
                geometry: {
                    type: 'LineString',
                    coordinates: convertToRainbowGradientLineCoordinates
                }
            }];
            lineLayer.setData(data);
        },
        // 绘制自定义覆盖物
        drawCustomCoverings(map) {
            let value = []
            if (this.state === -1) {
                value = this.getCityLatitudeAndLongitude(this.planDayMap.cityList)
            } else if (this.state === -2) {
                let cityList = []
                this.planDayList.forEach(item => {
                    cityList.push(...item.cityList)
                })
                value = this.getCityLatitudeAndLongitude(cityList)
                console.log("asuydusad",value)
            } else {
                value = this.getBugetListLatitudeAndLongitude(this.planDayMap.budgetList, this.state)
            }
            value = new Set(value)
            console.log("getCityLatitudeAndLongitude", value)
            let changeBaiduPoint = this.changeBaiduPoint(value);
            if (this.state === -1) {
                // map.setZoom(5)
                console.log("_find",this.planDayMap,changeBaiduPoint)
                changeBaiduPoint.forEach(item => {
                    console.log("item.lat",Number(item.lat))
                    let _find = this.planDayMap.cityList.find(x => Number(x.latitude) === Number(item.lat.toString()) && Number(x.longitude) === Number(item.lng.toString()))
                    if (_find) {
                        let cityOverlayPointUtil = new CityOverlayPointUtil(item, _find);
                        map.addOverlay(cityOverlayPointUtil);
                    }
                })
            }  else if (this.state === -2) {
                changeBaiduPoint.forEach(item => {
                    console.log("item.lat",Number(item.lat))
                    let cityList = []
                    this.planDayList.forEach(item => {
                        cityList.push(...item.cityList)
                    })
                    let _find = cityList.find(x => Number(x.latitude) === Number(item.lat.toString()) && Number(x.longitude) === Number(item.lng.toString()))
                    if (_find) {
                        let cityOverlayPointUtil = new CityOverlayPointUtil(item, _find);
                        map.addOverlay(cityOverlayPointUtil);
                    }
                })
            }
            else {
                changeBaiduPoint.forEach(item => {
                    let _find = this.planDayMap.budgetList.find(x => x.resourceDetail.latitude === item.lat.toString() && x.resourceDetail.longitude === item.lng.toString())
                    if (_find) {
                        console.log("找到的数据", _find.resourceDetail)
                        const spotOverlayPointUtil = new SpotOverlayPointUtil(item, _find.resourceDetail);
                        map.addOverlay(spotOverlayPointUtil);
                    }
                })
            }
        },
        initMap() {
            // 创建地图实例  个性化地图遵照官网步骤
            let map;
            if (!this.planMap) {
                this.planMap = new BMapGL.Map("CityDetailMap");
            }
            map = this.planMap
            //设置地图为地球模式 （确定 自定义点覆盖物出不来 而且线的宽度不受控制）
            // map.setMapType(BMAP_EARTH_MAP);
            // 创建中心点
            const point = new BMapGL.Point(106.55839021637583, 29.626064510293723);
            // // 初始化地图，设置中心点坐标和地图级别
            // map.centerAndZoom(point, 6);
            //开启鼠标滚轮缩放
            map.enableScrollWheelZoom(true);
            map.setMapStyleV2(
                {
                    styleId: '3c39515205c6228e3678e5aaffd9800c'
                    // styleId: "552bebe70f61ccf4ab9173df1169aa6e"
                }
            );
            map.addEventListener("dragend", () => {
            })
            map.addEventListener("zoomend", () => {
            })
            this.lineLayer()
            this.drawCustomCoverings(map)
        },

    },
    // 计算属性 类似于 data 概念
    computed: {
        ...mapState('collection', ['dayData', 'budgetMainId', 'planId'])
    },
    // 监控 data 中的数据变化
    watch: {},
    //过滤器
    filters: {},
    // 生命周期 - 创建之前
    beforeCreate() {
    },
    // 生命周期 - 创建完成（可以访问当前this 实例）
    created() {
    },
    // 生命周期 - 挂载之前
    beforeMount() {
    },
    // 生命周期 - 挂载完成（可以访问 DOM 元素）
    mounted() {
        setTimeout(() => {
            this.initMap()
        }, 100)
    },
    // 生命周期 - 更新之前
    beforeUpdate() {
    },
    // 生命周期 - 更新之后
    updated() {
    },
    // 生命周期 - 销毁之前
    beforeDestroy() {
    },
    // 生命周期 - 销毁完成
    destroyed() {
        setTimeout(() => {this.planMap.destroy()},0)
    },
    // 如果页面有 keep-alive 缓存功能,这个函数会触发
    //进入的时候触发
    activated() {
    },
    //离开的时候触发
    deactivated() {
    },
}
</script>
<style>
.anchorBL {
    opacity: 0;
}
</style>
<style scoped lang="scss">
/deep/ .van-uploader__wrapper--disabled {
    opacity: 1;
    position: relative;
    left: 50%;
    transform: translateX(-43%);
}

@keyframes scrolltext {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

.CPM11 {
    width: auto;
    height: 55vh;
}

.Map-Describe {
    height: 46vh;

    .GeneralSituation {
        height: 46vh;
        overflow-y: scroll;
        padding: 10px;

        .GeneralSituation-Inside {
            .Pic {
                margin-top: 10px;
            }

            .Index {
                width: 20px;
                height: 20px;
                border: 1px solid #ee0a24;
                display: inline-block;
                position: relative;
                left: -6px;
                text-align: center;
                color: #ee0a24;
            }

            .Title {
                display: flex;
                align-items: baseline;
                position: relative;
                left: -13px;
                top: -4px;
                z-index: 100;

                .Playtime {
                    font-size: 12px;
                    margin-left: 10px;
                    color: #ee0a24;
                    white-space: nowrap;
                }
            }

            > p {
                font-size: 12px;
                margin-top: 3px;
                text-align: justify;
                text-align-last: left;
                /*兼容ie*/
                text-justify: distribute-all-lines;
            }

            border-left: 1px dashed #ee0a24;
            padding-left: 5px;
            padding-bottom: 5px;
            margin-bottom: 10px;
            position: relative;
            z-index: 1;
        }
    }
}
</style>
