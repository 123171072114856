var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("van-action-sheet", {
    attrs: {
      title: "使用地图打开",
      actions: _vm.sheetList,
      "cancel-text": "取消",
      description: "如果点击无响应，可能是您还没有安装该APP",
      "close-on-click-action": "",
    },
    on: { select: _vm.handleSheetSelect },
    model: {
      value: _vm.isShowSheet,
      callback: function ($$v) {
        _vm.isShowSheet = $$v
      },
      expression: "isShowSheet",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }