var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { width: "auto", "margin-top": "20px" } }, [
    _c(
      "div",
      { staticClass: "cityNode" },
      [
        _c(
          "van-notice-bar",
          {
            attrs: {
              color: "#1989fa",
              background: "#ecf9ff",
              "left-icon": "info-o",
            },
          },
          [_vm._v(" 地图概览 ")]
        ),
        _c("div", { staticClass: "CPM11", attrs: { id: "CityDetailMap" } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }