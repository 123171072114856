export const getDateYearSubNew = function (startDateStr, endDateStr) {
    var day = 24 * 60 * 60 * 1000;

    var sDate = new Date(Date.parse(startDateStr.replace(/-/g, "/")));
    var eDate = new Date(Date.parse(endDateStr.replace(/-/g, "/")));

    //得到前一天(算头不算尾)
    sDate = new Date(sDate.getTime() - day);

    //获得各自的年、月、日
    var sY = sDate.getFullYear();
    var sM = sDate.getMonth() + 1;
    var sD = sDate.getDate();
    var eY = eDate.getFullYear();
    var eM = eDate.getMonth() + 1;
    var eD = eDate.getDate();

    if (eY > sY && sM == eM && sD == eD) {
        return eY - sY;
    } else {
        return 0;
    }
}


export const getDateStr = function (originVal) {
    var hour = new Date(originVal).getTimezoneOffset() * 60000
    var date = new Date(originVal + 28800000 + hour),
        year = date.getFullYear(),
        m = parseInt(date.getMonth()),
        mounth = m + 1 < 10 ? "0" + (m + 1) : m + 1,
        day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    return year + "-" + mounth + "-" + day;
}

function padLeftZero(str) {
    return ("00" + str).substr(str.length);
}

// 时间格式化
export const formatDate = function (date, fmt) {
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    }

    let o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds()
    };

    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + "";
            fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str));
        }
    }

    return fmt;
}