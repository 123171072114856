import wx from 'weixin-js-sdk';
import {ServiceToC} from "@/components/Axios/Service";
import qs from "qs";


export const WeChatGlMixins =   {
    methods: {
        // 微信初始化
// api 获取签名的后台接口
// url 需要进行微信初始化的路径
        config(api,url) {
            ServiceToC({
                url: api,
                method: "post",
                data: {pageUrl: url}
            }).then(result => {
                result= JSON.parse(result);
                var appId = result.appId;
                var timeStamp = result.timestamp;
                var nonceStr = result.noncestr;
                var Sign = result.signature;
                //--------初始化----------
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: appId, // 必填，公众号的唯一标识
                    timestamp: timeStamp, // 必填，生成签名的时间戳
                    nonceStr: nonceStr, // 必填，生成签名的随机串
                    signature: Sign,// 必填，签名
                    jsApiList: [
                        'getLocation',
                        'openLocation'
                    ] // 必填，需要使用的JS接口列表
                });
                this.getByLocation();
            })
        },
        // 获取地理坐标
        getByLocation(){
            let _this=this
            wx.ready(function(){
                wx.getLocation({
                    type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                    success: function (res){
                        // alert('getByLocation：'+res);
                        console.log('纬度',res.longitude)
                        console.log('精度',res.latitude)
                        _this.latitude= res.latitude
                        _this.longitude= res.longitude
                    },
                    error: function(res){
                        alert('我打印的错误：'+res)
                    }
                })
            })
        }
    }
}
